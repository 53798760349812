import React from "react";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import Box from "@cloudscape-design/components/box";

export default function SalesSummary() {
  let todayOrderCount = 218;
  let todayTotalSales = 25323;
  let currency = "INR";
  let thirtyOrderCount = 3501;
  let thirtyTotalSales = 323233;
  return (
    <React.Fragment>
      <Container
        header={
          <Header
            variant="h2"
            description="Sales summary of today and last 30 days."
          >
            Sales Summary
          </Header>
        }
      >
        <Box margin={{ top: "m" }}>
          <ColumnLayout columns={4} borders="all">
            <div>
              <Box variant="awsui-key-label" textAlign="center">
                Today Order Count
              </Box>
              <Box
                fontSize="heading-xl"
                textAlign="center"
                fontWeight="bold"
                color="text-status-info"
              >
                {todayOrderCount}
              </Box>
            </div>
            <div>
              <Box variant="awsui-key-label" textAlign="center">
                Today Sales Amount
              </Box>
              <Box
                fontSize="heading-xl"
                textAlign="center"
                fontWeight="bold"
                color="text-status-info"
              >
                {currency + " " + todayTotalSales}
              </Box>
            </div>
            <div>
              <Box variant="awsui-key-label" textAlign="center">
                Last 30 Days Order Count
              </Box>
              <Box
                fontSize="heading-xl"
                textAlign="center"
                fontWeight="bold"
                color="text-status-info"
              >
                {thirtyOrderCount}
              </Box>
            </div>
            <div>
              <Box variant="awsui-key-label" textAlign="center">
                Last 30 Days Sales Amount
              </Box>
              <Box
                fontSize="heading-xl"
                textAlign="center"
                fontWeight="bold"
                color="text-status-info"
              >
                {currency + " " + thirtyTotalSales}
              </Box>
            </div>
          </ColumnLayout>
        </Box>
      </Container>
    </React.Fragment>
  );
}
