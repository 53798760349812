import * as React from "react";

import ColumnLayout from "@cloudscape-design/components/column-layout";
import Box from "@cloudscape-design/components/box";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import LineChart from "@cloudscape-design/components/line-chart";

export default function TodayOrderChart() {
    return (
        <React.Fragment>
            <Container
                header={
                    <Header
                        variant="h2"
                        description="Order count and sales amount of each hour of today."
                    >
                        Today Sales
                    </Header>
                }
            >
                <ColumnLayout columns={2}>
                    <OrderCountChart />
                    <TotalSalesChart />
                </ColumnLayout>
            </Container>
        </React.Fragment>
    );
}

function OrderCountChart() {
    return (
        <LineChart
            series={[
                {
                    title: "Orders Count",
                    type: "line",
                    data: [
                        { x: new Date(1601017200000), y: 20 },
                        { x: new Date(1601020800000), y: 2 },
                        { x: new Date(1601024400000), y: 20 },
                        { x: new Date(1601028000000), y: 91 },
                        { x: new Date(1601031600000), y: 21 },
                        { x: new Date(1601035200000), y: 50 },
                        { x: new Date(1601038800000), y: 42 },
                        { x: new Date(1601042400000), y: 32 },
                        { x: new Date(1601046000000), y: 18 },
                        { x: new Date(1601049600000), y: 45 },
                        { x: new Date(1601053200000), y: 67 },
                        { x: new Date(1601056800000), y: 31 },
                        { x: new Date(1601060400000), y: 59 },
                        { x: new Date(1601064000000), y: 88 },
                    ],
                },
            ]}
            xDomain={[new Date(1601017200000), new Date(1601104000000)]}
            i18nStrings={{
                xTickFormatter: (e) =>
                    e
                        .toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: !1,
                        })
                        .split(",")[1],
                yTickFormatter: undefined,
            }}
            emphasizeBaselineAxis={true}
            errorText="Error loading data"
            height={300}
            hideFilter
            hideLegend
            loadingText="Loading chart"
            xScaleType="time"
            xTitle="Time"
            yTitle="Orders Count"
            statusType="finished"
        />
    );
}

function TotalSalesChart() {
    let currency = "INR";
    return (
        <LineChart
            series={[
                {
                    title: "Sales Amount (" + currency + ")",
                    type: "line",
                    data: [
                        { x: new Date(1601017200000), y: 5802 },
                        { x: new Date(1601020800000), y: 10240 },
                        { x: new Date(1601024400000), y: 10492 },
                        { x: new Date(1601031600000), y: 9403 },
                        { x: new Date(1601035200000), y: 22502 },
                        { x: new Date(1601038800000), y: 79000 },
                        { x: new Date(1601042400000), y: 22403},
                        { x: new Date(1601046000000), y: 38204 },
                        { x: new Date(1601049600000), y: 23200 },
                        { x: new Date(1601053200000), y: 44734 },
                        { x: new Date(1601056800000), y: 39087 },
                        { x: new Date(1601060400000), y: 77856 },
                        { x: new Date(1601064000000), y: 59779 },
                        
                    ],
                },
            ]}
            xDomain={[new Date(1601017200000), new Date(1601104000000)]}
            i18nStrings={{
                xTickFormatter: (e) =>
                    e
                        .toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: !1,
                        })
                        .split(",")[1],
                yTickFormatter: undefined,
            }}
            errorText="Error loading data"
            height={300}
            hideFilter
            hideLegend
            loadingText="Loading chart"
            xScaleType="time"
            xTitle="Time"
            yTitle={"Sales Amount (" + currency + ")"}
            statusType="finished"
            emphasizeBaselineAxis={true}
        />
    );
}
