import TopNavigation from "@cloudscape-design/components/top-navigation";
import React from "react";
import logo from "logo.png";
import ServicesModal from "../ServicesModal/ServicesModal";
import { applyMode, Mode } from "@cloudscape-design/global-styles";

const LOCAL_STORAGE_DARK_MODE_KEY = "dark-mode";

export default function Navigation({ navigationId }) {
    const customerName = "Sumit";
    const customerEmail = "sumit@gmail.com";
    const origin = window.location.origin;
    const merchantName = "Izoo";
    const countryCode = "IN";

    const [servicesModalVisible, setServicesModalVisible] =
        React.useState(false);

    const [darkMode, setDarkMode] = React.useState(
        localStorage.getItem(LOCAL_STORAGE_DARK_MODE_KEY) === "true"
    );

    React.useEffect(() => {
        if (darkMode) {
            localStorage.setItem(LOCAL_STORAGE_DARK_MODE_KEY, "true");
            applyMode(Mode.Dark);
        } else {
            localStorage.setItem(LOCAL_STORAGE_DARK_MODE_KEY, "false");
            applyMode(Mode.Light);
        }
    }, [darkMode]);
    return (
        <React.Fragment>
            <TopNavigation
                id={navigationId}
                identity={{
                    logo: {
                        src: logo,
                        alt: "Brand logo",
                    },
                }}
                utilities={[
                    // TODO: in future, we need to show this button conditional. if valid seller-marketplace, then
                    // only show this button.
                    {
                        type: "button",
                        onClick: () => setServicesModalVisible(true),
                        text: "Services",
                        iconUrl:
                            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAADJ0lEQVR4nO2bv05UQRTGvyN0QiKIiWgtFViKUaAFXsDwANa2BmgMvoCREl9AoBYegYUECWCjrbtULgVL1mLZz+LukuHuDJk/m5xE5lfN7t77mzNfsuydw71AJpPJ3GHE9QHJKQBvAYx7uq4AnABYF5GLkmsJwDyA+56uSwC7IvK15BkG8A7AJIABT9cZgA0ROfU8vlg8ySbjqJAcNFwrkR6SXDY8gyQPIj1NkpMhAXxOKJokXxmuWoKnZnhmEmv6ZFvrPUcGj73TsvPUGPt+hWyMO8YxPLG96QrgzpAD0C5AmxyAdgHa5AC0C9AmB6BdgDauANqJ3ivHWMvjPN8VwHHCRESxK+xy4jrQA7OO0467H67bITnMYlcXSpvkh5JrlmQ9wvWH5OuSa60zRyh7JIdsa72tHzAIYBqOTYSFKwAnIvLL4hrtuKxFWGgA2BORc4vrGYAp+PcDqgD2RaTleXwmAwAkVxnWzGiR/E5ytuR5RHKbZCPA1SC5RXKs5JojedSZy5cajc6S7+KXAiYoUyc5Yri2E1ybhmeU5HmC641tra6fwfmgxG4yAuCl8XohwbVojKcBPOiT6xpXAL7dWxfmX/sU15BjHIO1jnwprF2ANjkA7QK0yQFoF6BNDkC7AG1yAI73LxO9jT65Go5xDNY6XAHsJkxUB7BnvN5JcJnnVgD0NEgC+BZ0NMllktWA3VaL5CHJmZJnjMXW9iLAdUFyk73b4dnOHCHb4SrJ9wnBZf5r+t0UPRWRnxbXQwAvENYUrYhI3eKaQNhNUrWOy78pyqItHnNDUpvkWsk1x7hOTp297bWPjGuLV1jcYeYdwGrEJF3aLFrXXddRguvQ8EwwbvFdVmxrdf0MTnmn1YuUzve/Pa2X5yWP8yvrgXVNrgBSrxAHHGMtj/P8fCmsXYA2OQDtArTJAWgXoE0OQLsAbXIAjvfPEr2/++SqGuOa86hw1zWuADYA/I2cqALgwHi9Hukpn1sBsB/paQL4Yvvgtn7AJIqHpkL6AccoHpq60cBkcXPCIvz/Vd4AsCMiWyXPEIqHpkJukqqheGjqh+fxmUwmc3f4BxRSWdYZ7noiAAAAAElFTkSuQmCC",
                        iconAlt: "Services",
                        disableTextCollapse: true,
                    },
                    {
                        type: "button",
                        href: origin + "/home-dashboard",
                        iconUrl:
                            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAABrklEQVRoge3XMWsUQRiA4W8lKIJGEbQQi5Q2gk2uEiwEwc5Ou1hZ2+lPsLW0tRIEEcRSLAwISsDGRmxsRBRBRA4xMY9FdvDcHN5dsrc7xHlg29nvZdmZ3YhiMizhWX0t9T3PjuASvvjjKy73PdfUUOEmftluE7exr+85/wmLeDgmoOkJjvU971g4i3dTRCTvsdxYY6wuI1YwnCEiGWJlZJ0b+Nl5CA7gzg4Cmu7hYL3mOXzoLASn8KKFiGRNvUXjOJ7OPQTn8bHFiOQzLtb3WLC1w23OIyBtrRtziEg2cAtVfc8rbUccxoM5BjQ9xtG2I07jTYcRyVucaSviKr73EJEMcW03Aelly8Vd7J814oSR7S8jqzg5bcS2Aykzn3BhUsR1Yz4RMrRu6xiomgGHcL/f2XbkEY5ERKRD52VELEfEt4hYnOmF6k+a9VVVVYP0g7MeEa8jYtDbWLMbRMRaRPyIqJ/IKDr8/t+Fqqr+mj3vX84ZlJDclJDclJDcLLS5WHNvn6TNM2vPPJESkpsSkpsSkpsSkpsSkpsSkpsSkps9HbLa+RSze973AMV/4zedbIl5sCPhmAAAAABJRU5ErkJggg==",
                        text: "Home",
                        disableTextCollapse: true,
                    },
                    {
                        type: "button",
                        text: merchantName + " | " + countryCode,
                        disableTextCollapse: true,
                    },
                    {
                        type: "menu-dropdown",
                        text: customerName,
                        description: customerEmail,
                        iconName: "user-profile",
                        onItemClick: (event) => {
                            switch (event.detail.id) {
                                case "dark-mode":
                                    setDarkMode(true);
                                    break;

                                case "light-mode":
                                    setDarkMode(false);
                                    break;
                            }
                        },
                        items: [
                            darkMode
                                ? {
                                      id: "light-mode",
                                      text: "Light Mode",
                                  }
                                : {
                                      id: "dark-mode",
                                      text: "Dark Mode",
                                  },
                            {
                                id: "account-maangement",
                                text: "Account Management",
                            },
                            {
                                id: "access-management",
                                text: "Access Management",
                            },
                            {
                                id: "support",
                                text: "Support",
                                href: "/support",
                            },
                            { id: "signout", text: "Sign out" },
                        ],
                    },
                ]}
                i18nStrings={{
                    overflowMenuTriggerText: "More",
                    overflowMenuTitleText: "All",
                    overflowMenuBackIconAriaLabel: "Back",
                    overflowMenuDismissIconAriaLabel: "Close menu",
                }}
            />
            <ServicesModal
                servicesModalVisible={servicesModalVisible}
                setServicesModalVisible={setServicesModalVisible}
            />
        </React.Fragment>
    );
}
