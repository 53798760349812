import React from "react";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Box from "@cloudscape-design/components/box";
import Link from "@cloudscape-design/components/link";
import Icon from "@cloudscape-design/components/icon";

export default function Services() {
    const origin = window.origin;
    const sections = {
        tracking: {
            icon: "status-pending",
            name: "Tracking",
        },
        operations: {
            icon: "settings",
            name: "Operations",
        },
        dashboards: {
            icon: "search",
            name: "Dashboards",
        },
        finance: {
            icon: "calendar",
            name: "Finance",
        },
    };

    const services = {
        "product-tracker": {
            name: "Product Tracker",
            description:
                "Track lowest price, buybox price, and rank",
        },
        "product-research": {
            name: "Product Research",
            description: "Research products to sell on Amazon"
        },
        "product-fee-estimator": {
            name: "Product Fee Estimator",
            description: "Estimate different types of product fees"
        },
        "review-requester": {
            name: "Review Requester",
            description: "Automate request a review process"
        },
        "sales-dashboard": {
            name: "Sales Dashboard",
            description: "See historical sales of various products in inventory"
        },
        "orders-dashboard": {
            name: "Orders Dashboard",
            description: "List of orders of given seller"
        },
        "refund-generator": {
            name: "Refund Generator",
            description: "Provides information about refund from Amazon"
        },
        "financial-analysis": {
            name: "Financial Analysis",
            description: "Financial Analysis of seller account",
        },
    };

    const servicesList = {
        IN: [
            {
                section: "tracking",
                services: [
                    {
                        service: "product-tracker",
                    },
                    {
                        service: "product-research",
                    },
                ],
            },
            {
                section: "operations",
                services: [
                    {
                        service: "review-requester",
                    },
                    {
                        service: "product-fee-estimator",
                    },
                ],
            },
            {
                section: "dashboards",
                services: [
                    {
                        service: "sales-dashboard",
                    },
                    {
                        service: "orders-dashboard",
                    },
                ],
            },
            {
                section: "finance",
                services: [
                    {
                        service: "refund-generator",
                    },
                    {
                        service: "financial-analysis",
                    },
                ],
            },
        ],
    };

    const country = "IN";
    return (
        <ColumnLayout
                columns={
                    servicesList[country].length <= 4
                        ? servicesList[country].length
                        : 4
                }
            >
                {servicesList[country].map((section) => (
                    <SpaceBetween size="xxl" key={section.section}>
                        <div>
                            <Box
                                variant="h3"
                                margin={{
                                    bottom: "xs",
                                }}
                            >
                                <Icon
                                    name={sections[section.section].icon}
                                    size="inherit"
                                />
                                &nbsp; {sections[section.section].name}
                            </Box>
                            <SpaceBetween size="s">
                                {section.services.map((service) => (
                                    <div key={service.service}>
                                        <SpaceBetween>
                                            <Box fontWeight="bold">
                                                <Link
                                                    href={
                                                        origin +
                                                        "/" +
                                                        (["financial-analysis", "orders-dashboard", "product-fee-estimator"].includes(service.service) ? "home-dashboard": service.service)
                                                    }
                                                    fontSize="heading-s"
                                                >
                                                    {
                                                        services[
                                                            service.service
                                                        ].name
                                                    }
                                                </Link>
                                            </Box>
                                            <Box fontSize="body-s">
                                                {
                                                    services[service.service]
                                                        .description
                                                }
                                            </Box>
                                        </SpaceBetween>
                                    </div>
                                ))}
                            </SpaceBetween>
                        </div>
                    </SpaceBetween>
                ))}
            </ColumnLayout>
    );
}
