import * as React from "react";
import Modal from "@cloudscape-design/components/modal";
import Services from "../Services/Services";

export default function ServicesModal({
    servicesModalVisible,
    setServicesModalVisible,
}) {
    return (
        <Modal
            onDismiss={() => setServicesModalVisible(false)}
            visible={servicesModalVisible}
            closeAriaLabel="Close modal"
            disableContentPaddings
            size="large"
            footer={<Services />}
            header="Services"
            id="services"
        ></Modal>
    );
}
