import Services from "./Services/Services";
import ServicesModal from "./ServicesModal/ServicesModal";
import Navigation from "./Navigation/Navigation";
import Product from "./Product/Product";
import SearchGrid from "./SearchGrid/SearchGrid";

export { Services };
export { ServicesModal };
export { Navigation };
export { Product };
export { SearchGrid };

export const TOP_NAVIGATION_ID = "top-navigation";
export const STANDARD_SPACE_BETWEEN_CONTAINERS = "m";

// Having single space in breadcrumb helps to provide proper margin above header in case breadcrumb is empty.
export const DEFAULT_BREADCRUMB = " ";

// Chart statuses
export const CHART_STATUS_LOADING = "loading";
export const CHART_STATUS_FINISHED = "finished";
export const CHART_STATUS_ERROR = "error";

// Search bar dropdown options
export const ASIN_OPTION = { label: "ASIN", value: "asin" };
export const SKU_OPTION = { label: "SKU", value: "sku" };
export const KEYWORDS_OPTION = { label: "Keywords", value: "keywords" };
export const ORDER_ID_OPTION = { label: "Order ID", value: "order_id" };
