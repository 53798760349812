import * as React from "react";
import BarChart from "@cloudscape-design/components/bar-chart";

import ColumnLayout from "@cloudscape-design/components/column-layout";
import Box from "@cloudscape-design/components/box";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";

export default function TodayOrderChart() {
    return (
        <React.Fragment>
            <Container
                header={
                    <Header
                        variant="h2"
                        description="Order count and sales amount of each day of last 30 days."
                    >
                        Last 30 Day Sales
                    </Header>
                }
            >
                <ColumnLayout columns={2}>
                    <OrderCountChart />
                    <TotalSalesChart />
                </ColumnLayout>
            </Container>
        </React.Fragment>
    );
}

function OrderCountChart() {
    return (
        <BarChart
            series={[
                {
                    title: "Orders Count",
                    type: "bar",
                    data: [
                        { x: new Date(1715843700000), y: 48 },
                        { x: new Date(1715930100000), y: 72 },
                        { x: new Date(1716016500000), y: 81 },
                        { x: new Date(1716102900000), y: 90 },
                        { x: new Date(1716189300000), y: 58 },
                        { x: new Date(1716275700000), y: 129 },
                        { x: new Date(1716362100000), y: 178 },
                        { x: new Date(1716448500000), y: 157 },
                        { x: new Date(1716534900000), y: 140 },
                        { x: new Date(1716621300000), y: 210 },
                        { x: new Date(1716707700000), y: 239 },
                        { x: new Date(1716794100000), y: 147 },
                        { x: new Date(1716880500000), y: 119 },
                        { x: new Date(1716966900000), y: 222 },
                        { x: new Date(1717053300000), y: 179 },
                    ],
                },
            ]}
            i18nStrings={{
                xTickFormatter: (e) =>
                    e
                        .toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: !1,
                        })
                        .split(",")[0],
                yTickFormatter: undefined,
            }}
            errorText="Error loading data."
            height={300}
            hideFilter
            hideLegend
            loadingText="Loading chart"
            xScaleType="categorical"
            xTitle="Day"
            yTitle="Orders Count"
            statusType="finished"
            emphasizeBaselineAxis={true}
        />
    );
}

function TotalSalesChart() {
    let currency = "INR";
    return (
        <BarChart
            series={[
                {
                    title: "Sales Amount (" + currency + ")",
                    type: "bar",
                    data: [
                        { x: new Date(1715843700000), y: 38352 },
                        { x: new Date(1715930100000), y: 43128 },
                        { x: new Date(1716016500000), y: 64719 },
                        { x: new Date(1716102900000), y: 71910 },
                        { x: new Date(1716189300000), y: 57942 },
                        { x: new Date(1716275700000), y: 77271 },
                        { x: new Date(1716362100000), y: 124422 },
                        { x: new Date(1716448500000), y: 141142 },
                        { x: new Date(1716534900000), y: 69860 },
                        { x: new Date(1716621300000), y: 125790 },
                        { x: new Date(1716707700000), y: 95361 },
                        { x: new Date(1716794100000), y: 146853 },
                        { x: new Date(1716880500000), y: 118881 },
                        { x: new Date(1716966900000), y: 177378 },
                        { x: new Date(1717053300000), y: 89548 },
                    ],
                },
            ]}
            i18nStrings={{
                xTickFormatter: (e) =>
                    e
                        .toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: !1,
                        })
                        .split(",")[0],
                yTickFormatter: undefined,
            }}
            errorText="Error loading data"
            height={300}
            hideFilter
            hideLegend
            loadingText="Loading chart"
            xScaleType="categorical"
            xTitle="Day"
            yTitle={"Sales Amount (" + currency + ")"}
            statusType="finished"
            emphasizeBaselineAxis={true}
        />
    );
}
