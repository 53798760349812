import "./App.css";
import { Services } from "common-components";
import AppLayout from "@cloudscape-design/components/app-layout";
import React from "react";
import { Navigation } from "common-components";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import ContentLayout from "@cloudscape-design/components/content-layout";
import SalesSummary from "./SalesSummary/SalesSummary";
import TodayOrderChart from "./TodayOrderChart/TodayOrderChart";
import ThirtyDayChart from "./ThirtyDayChart/ThirtyDayChart";
import { TOP_NAVIGATION_ID } from "common-components";
import { STANDARD_SPACE_BETWEEN_CONTAINERS } from "common-components";
import { DEFAULT_BREADCRUMB } from "common-components";

export default function App() {
    const [breadcrumbs, setBreadcrumbs] = React.useState(DEFAULT_BREADCRUMB);
    return (
        <React.Fragment>
            <Navigation navigationId={TOP_NAVIGATION_ID} />
            <AppLayout
                headerSelector={TOP_NAVIGATION_ID}
                toolsHide={true}
                navigationHide={true}
                breadcrumbs={breadcrumbs}
                content={
                    <ContentLayout
                            header={
                                <div>
                                    <Header
                                        variant="h1"
                                        description="Lists all services, shows sales summary of today and last thirty days, shows
                                        historical sales data of today and last thirty days."
                                    >
                                        Home Dashboard
                                    </Header>
                                </div>
                            }
                        >
                            <SpaceBetween
                                size={STANDARD_SPACE_BETWEEN_CONTAINERS}
                            >
                                <Container
                                    disableContentPaddings
                                    header={
                                        <Header variant="h2">Services</Header>
                                    }
                                    footer={<Services />}
                                />
                                <SalesSummary />
                                <TodayOrderChart />
                                <ThirtyDayChart />
                            </SpaceBetween>
                        </ContentLayout>
                }
            ></AppLayout>
        </React.Fragment>
    );
}
